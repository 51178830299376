import Client from './HttpClient';

const getIntro = (merchantId, shareCode = false) => {
  return Client.get(`/wombat-api/right-info/${merchantId}/join-rights`, {
    inviteRightFlag: shareCode,
  });
};

const registerMember = (customerId, merchantId) => {
  return Client.post(`/wombat-api/member-info/${customerId}/members`, {
    merchantId,
  });
};

const isMember = (customerId, merchantId) => {
  return Client.get(`/wombat-api/member-info/${customerId}/member`, {
    merchantId,
  });
};

const rights = (customerId, merchantId) => {
  return Client.get(
    `/wombat-api/right-info/${merchantId}/rights/${customerId}`
  );
};

const coupons = (customerId, merchantId, status, page, pageSize) => {
  const payload = {
    merchantId,
    page,
    pageSize,
    type: status,
  };
  return Client.get(`/wombat-api/coupon-info/${customerId}/coupons`, payload);
};
const pointActivities = (customerId, merchantId, page, pageSize) => {
  const payload = {
    page,
    pageSize,
  };
  return Client.get(
    `/wombat-api/point-info/${merchantId}/point-activities/${customerId}`,
    payload
  );
};

const depositActivities = (customerId, merchantId, page, pageSize) => {
  const payload = {
    page,
    pageSize,
  };
  return Client.get(
    `/wombat-api/card-info/${merchantId}/fund-activities/${customerId}`,
    payload
  );
};

const invite = (customerId, joinMerchantId, inviteeEmail, inviteeMobile) => {
  return Client.post(`/wombat-api/user-info/${customerId}/invite-activities`, {
    inviteeEmail,
    inviteeMobile,
    joinMerchantId,
  });
};

const grades = (customerId, merchantId) => {
  return Client.get(`/wombat-api/right-info/${merchantId}/grade-rights`, {
    customerId,
  });
};
const gradeList = (merchantId) => {
  return Client.get(`/wombat-api/manage-member/${merchantId}/grades`);
};

const getPaymentAuthCode = (customerId) => {
  return Client.post(`/wombat-api/auth/payment-authority-code`, {
    customerId,
  });
};

const refreshPaymentAuthCode = (customerId, paymentAuthorityCode) => {
  return Client.post(`/wombat-api/auth/${customerId}/payment-token`, {
    paymentAuthorityCode,
  });
};

export default {
  getIntro,
  registerMember,
  isMember,
  rights,
  coupons,
  pointActivities,
  depositActivities,
  invite,
  grades,
  gradeList,
  getPaymentAuthCode,
  refreshPaymentAuthCode,
};
